<template>
  <v-card flat class="fire-studio--container">
    <router-view v-if="user" @hook:mounted="studioMounted=true" />
    <v-card v-if="!studioMounted" flat>
      <template v-if="!authError">
        <v-card-title class="justify-center">
          Loading Studio
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular indeterminate />
        </v-card-text>
      </template>
      <template v-else>
        <v-card-text>
          <v-alert outlined type="error">
            An error occured when trying to login to your studio.
          </v-alert>
          <p>
            <strong>Error details:</strong>
            <br>
            {{ authError.message }} ({{ authError.code }})
          </p>
          <template v-if="authError.tip">
            <p>
              <strong>What you can try:</strong>
              <br>
              {{ authError.tip }}
            </p>
          </template>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            outlined
            @click="retry"
          >
            Retry
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ProjectStudio',
  data: () => ({
    studioMounted: false
  }),
  computed: {
    ...mapState('firebase', ['user', 'authError'])
  },
  methods: {
    retry() {
      window.location.reload()
    }
  }
}
</script>

<style lang="sass" scoped>
.fire-studio--container
  height: calc(100vh - 56px)
  display: flex
  align-items: center
  justify-content: center
</style>
